@import '../../styles/variables/colors';

.subHeading {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
  margin: 0.2rem 0 0.5rem 0 !important;
  padding-bottom: 0.6rem !important;
}

.calendar {
  height: 60rem !important;
  width: 100% !important;
}

.staffBadge {
  margin-top: 10px !important;
}

.periodTitle {
  color: #212529 !important;
  font-family: 'Inter', sans-serif !important;
}

.holidayEvent {
  background-color: $light-yellow !important;
  color: $black !important;
  display: block !important;
  padding: 4px !important;
  border-radius: 4px !important;
}
