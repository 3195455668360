@import '../../../styles/variables/colors';

.main {
  display: flex;
  align-items: center;
}

.image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-right: 60px;
}

.changeWrapper {
  height: 40px;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid $gray;
}

.link {
  outline: none;
  border: none;
  text-decoration: underline;
  color: $danger;
  background: none;
  margin-left: 10px;
}
