.popup {
  width: 600px;
}

.container {
  display: flex !important;
  align-items: center;
}

.orLabel {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
}
