@use '~styles/variables' as *;

.root,
.stroke {
  transition-property: fill, stroke;
}

.interactive {
  cursor: pointer;
}

.none {
  fill: none;
}

.color {
  &-none {
    fill: none;

    .stroke {
      stroke: none;
    }

    .fill {
      fill: none;
    }
  }

  &-white {
    fill: $color-white;

    .stroke {
      stroke: $color-white;
    }

    .fill {
      fill: $color-white;
    }
  }

  &-black {
    fill: $color-black;

    .stroke {
      stroke: $color-black;
    }

    .fill {
      fill: $color-black;
    }
  }
}
