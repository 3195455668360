.form {
  :global(.form-group) {
    margin-bottom: 0;
  }

  :global(.form-check-input) {
    position: relative;
    margin-top: 0.3rem;
    margin-left: 0;
    margin-right: 10px;
  }

  label {
    font-size: 16px;
    font-weight: 700;
  }

  .error {
    font-size: 14px;
    color: red;
  }

  .button {
    margin-left: auto;
    display: block;
  }
}
