.right {
  text-align: right;
}

.center {
  text-align: center;
}

.paddedRow {
  padding-top: 2rem;
}

.show {
  visibility: visible;
}

.hide {
  visibility: hidden;
}
