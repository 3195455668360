@import '../../styles/variables/colors.scss';

.root {
  min-height: 100%;
  margin-bottom: 10px !important;
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main {
  display: flex;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  margin-bottom: 0;
}

.caption {
  font-size: 16px;
  font-weight: 600;
  color: $gray;
}

.number {
  font-weight: bold;
  font-size: 30px;
  color: $dark;
  font-family: 'Inter', sans-serif;
}

.footer {
  border-top: thin solid rgba($gray, 0.25);
}

.icon {
  font-size: 26px;
  color: var(--brand);
  padding-left: 6px;
  margin-top: -5px;
}
