@import 'styles/variables/colors.scss';

.popup {
  width: 350px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinner {
  width: 60px;
  height: 60px;
}

.text {
  text-align: center;
  color: $black;
  font-size: 20px;
  margin-bottom: 16px;
}
