@import '../../styles/variables/colors';

.checkbox {
  margin-bottom: 45px;
}

.pinWrapper {
  position: relative;
}

.field {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.pinButton {
  position: absolute;
  right: 8px;
  top: 32px;
  outline: none;
  background: white;
  border: none;
  z-index: 9;
  color: rgba($brand, 0.7);
}
