.emptyCell {
  background-color: rgba(black, 0.02);
}

.head {
  &WithSubhead {
    padding: 0 !important;
    text-align: center;
  }
}

.subhead {
  display: flex;
  justify-content: space-between;

  &Col {
    padding: 5px 10px;
    text-align: center;
  }
}

.subContentContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  padding: 5px;
}

.inputBox {
  width: 50px;
}

.withInputBox {
  width: 50px;
}
