.filesWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.fileField {
  margin-right: 40px;
  margin-bottom: 30px;
}

.actionWrapper {
  display: flex;
  align-items: center;
}
