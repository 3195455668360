.popup {
  width: 80vw;
  max-width: 1000px !important;
  padding: 0;
}

.divider {
  margin-top: 20px;
  width: 100%;
  height: 2px;
  background: #e4e4e4;
}
