@import 'styles/variables/colors.scss';

.button {
  margin-top: 5px;
}

.buttonLink {
  padding: 0 !important;
  width: fit-content;
}

.narrowModal {
  max-width: 25em;
  padding: 1.5em;
}

.pill {
  width: 2em !important;
  height: 2em !important;
  line-height: 26px !important;
  margin: 0 !important;
}

.trashButton {
  margin-top: 10px;
  color: $secondary !important;
}

.trashButton:hover {
  color: $dark !important;
}

.subHeading {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0.2rem 0 0.5rem 0;
}

.actionColumn {
  width: 8em;
}

.rptContainer {
  margin: 1.6rem 0 0 -0.6rem;
}

.centered {
  text-align: center;
}

.dataCell {
  position: relative;
  vertical-align: top;
}

.dataCell > div {
  padding-top: 0.5rem;
  position: relative;
  height: 4rem;
}

.errorMessage {
  color: $danger;
  font-size: 0.8rem;
  position: absolute;
  top: 100%;
  left: 0;
  white-space: nowrap;
}
