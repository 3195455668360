.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.error {
  :global(.css-yk16xz-control) {
    border-color: red;
  }
}
