@import '../../styles/variables/colors';

.button {
  display: flex;
  height: 36px;
  border: none;
  background-color: $primary;
  align-items: center;
  color: $white;
  cursor: pointer;
  padding: 0 20px;
  border-radius: 6px;
  letter-spacing: 1.2px;
}

.inputContainer {
  margin-bottom: 10px !important;
}

.input {
  display: none;
}

.uploadedContainer {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.uploadedFile {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleteButton {
  background: none;
  cursor: pointer;
  border: none;
  padding: 0;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  height: 20px;
  margin-left: 10px;
}

.preview {
  display: block;
  object-fit: cover;
  // width: 100px;
  height: 100px;
  margin-bottom: 15px;
}
