//
// preloader.scss
//

.overflow-hidden {
  overflow: hidden;
}

#preloader {
  position: absolute;
  height: calc(100vh - (#{$header-height} + #{$grid-gutter-width}));
  width: 100%;
  top: calc(#{$header-height} + #{$grid-gutter-width});
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $body-bg;
  z-index: 9999;
}

#status {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin: -20px 0 0 -20px;
}

.spinner {
  .spin-icon {
    font-size: 56px;
    color: $primary;
    position: relative;
    display: inline-block;
    animation: spin 1.6s infinite linear;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
