.popupContainer {
  width: 32rem;
  height: 21rem;
}

.container {
  padding: 1rem;
}

.popupContainer h2 {
  font-size: 1.3em;
}
