.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.actionContainer {
  display: flex;
  justify-content: flex-end;
}
