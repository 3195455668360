.selectField {
  width: 100%;
  max-width: 200px;
  margin-right: 12px;
}

.alert {
  display: flex;
  align-items: center;
}
