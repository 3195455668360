@use '~styles/variables' as *;

.item {
  position: relative;
  display: flex;
  flex-direction: column;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: $color-mischka;
  }

  &:last-child {
    justify-content: flex-end;
  }
}

.cancelReasonTitle {
  max-width: 100px;
}

.cancelReasonWrapper {
  cursor: pointer;
}

.loadingContainer {
  transition: 0.2s;
  opacity: 0.4;
}

.w300 {
  width: 300px;
}

.table {
  border-top: none;
  margin-bottom: 75px !important;
}

.actionColumn {
  display: flex;
  flex-direction: column;
}

.subscriptionActionButton {
  margin-bottom: 10px;
}
