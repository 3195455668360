.tagsWrapper {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin: 10px 0 20px;
}

.tag {
  margin-right: 15px;
  margin-bottom: 15px;
}
