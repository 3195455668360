.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.selectedInformation {
  max-width: 500px;
}
