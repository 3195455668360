.container {
  width: 100%;
  height: 100%;
  margin-top: 40px;
}

.title {
  font-size: 18px;
  text-align: center;
  margin-bottom: 36px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    width: 100%;
  }
}
