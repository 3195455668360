@import 'styles/variables/colors';

.cell {
  min-width: 16rem;
}

.inventoryCell {
  width: 100%;
  display: flex;
  align-items: center;
}

.disabledCell {
  opacity: 0.3;
  cursor: default;
  user-select: none;
}

.trashButton {
  background: none;
  outline: none;
  border: none;
  font-size: 14px;
  color: $danger;
}

.quantityCell {
  width: 8vw;
}

.discountCell {
  width: 9vw;
}

.disabledNumberField {
  background-color: #f2f2f2 !important;
  border-color: #e6e6e6 !important;
}

.discountField {
  display: flex;
  align-items: center;
}

.inputWidth {
  width: 8rem;
}
