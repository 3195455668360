.customXXL {
  @media screen and (min-width: 1440px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33% !important;
    flex: 0 0 33% !important;
    max-width: 33% !important;
  }
}

.cardColumn {
  animation: fadeinfrombottom 0.3s ease-in-out forwards;
  opacity: 0;

  @keyframes fadeinfrombottom {
    0% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }

    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @for $i from 1 through 30 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 0.05s;
    }
  }
}
