.fieldWrapper {
  .field {
    margin-bottom: 0;

    & > div {
      margin-bottom: 0;
    }
  }
}

.fieldCell {
  text-transform: uppercase;
}
