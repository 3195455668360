.normal {
  width: 200px;
}

.large {
  min-width: 200px;
  max-width: 300px;
}

.small {
  width: 100px;
}

.w300 {
  width: 300px;
}
