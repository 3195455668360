@import '../../styles/variables/colors';

.bullet {
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 20px;
  background: #74788d;
  border-radius: 20px;
  pointer-events: none;
  transition: all 0.1s ease-in-out;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.toggle {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &::before {
    width: 50px;
    height: 28px;
    background: #f4f4f4;
    border-radius: 30px;
    content: '';
  }

  input[type='checkbox'] {
    width: 50px;
    height: 28px;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &:checked {
      & + .bullet {
        background: $primary;
        left: 24px;
      }
    }
  }
}

.label {
  color: #74788d;
  margin-bottom: 0;
  margin-left: 10px;
  cursor: pointer;
  user-select: none;
}
