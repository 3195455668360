.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.checkbox {
  display: flex !important;
  align-items: center;
  padding-left: 0 !important;

  label {
    margin-bottom: 0;
  }

  div {
    margin-left: 10px;
  }
}
