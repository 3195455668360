.filesWrapper {
  display: flex;
}

.fileField {
  margin-right: 40px;
  margin-bottom: 30px;
}

.previewButton {
  margin-top: 30px;
}
