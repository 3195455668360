.big {
  width: 200px;
}

.small {
  width: 100px;
}

.normal {
  width: 150px;
}

.w250 {
  width: 250px;
}
