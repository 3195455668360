.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.warehouseInfoHeadingContainer {
  height: 3rem;
  margin-top: 0.5rem;
}

.warehouseInfoHeading {
  margin: 0;
  font-size: 1.2rem;
}
