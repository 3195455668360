.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.customNavigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;

  &Input {
    width: 70px !important;
    margin-right: 10px !important;
  }
}
