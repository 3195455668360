.root {
  display: flex;
  flex-direction: column;
}

.message {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  text-align: center;
}

.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px 0;

  &Item {
    margin: 10px;
    position: relative;
  }
}

.checkbox {
  display: flex !important;
  align-items: center;
  padding-left: 0 !important;
  position: absolute !important;
  top: 5px !important;
  left: -1px !important;

  label {
    margin-bottom: 0;
  }

  div {
    margin-left: 10px;
  }
}

.image {
  max-width: 120px;
  max-height: 120px;

  &Container {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 2px solid #e4e4e4;
    // border-radius: 4px;
    // padding: 2px;
  }

  &Title {
    width: 120px;
    text-align: center;
  }
}

.preloader {
  background-color: white !important;
  height: 200px !important;
  position: relative !important;
  top: unset !important;
  left: unset !important;
  right: unset !important;
  bottom: unset !important;
}
