@import 'styles/variables/colors.scss';

.errorMessage {
  color: $danger;
  font-size: 0.75rem;
  position: absolute;
  top: 80%;
  left: 3%;
  white-space: nowrap;
}

.cardContainer {
  max-width: 66.5%;
}
