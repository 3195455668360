.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.actionColumn {
  width: 8em;
}

.checkbox {
  display: flex !important;
  align-items: center;
  padding-left: 0 !important;
}
