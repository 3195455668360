.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.width {
  max-width: 200px;
}

.dateField {
  margin: 0 20px 0 0 !important;

  & > div {
    margin-bottom: 0 !important;
  }
}

td {
  vertical-align: middle !important;
}
