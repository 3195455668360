.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.capitalize {
  text-transform: capitalize;
}

.actionColumn {
  width: 8em;
}
