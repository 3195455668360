.label {
  display: flex;
  align-items: center;
  margin: 0;
}

.input {
  &:global(.form-check-input) {
    position: relative;
    margin: 0 5px 0 0;
  }
}

.textarea {
  min-height: 100px;
  max-height: 150px;
}
