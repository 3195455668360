@import 'styles/variables/colors';

.subHeading {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0.2rem 0 0.5rem 0;
}

.loadingContainer {
  opacity: 0.4;
  transition: 0.2s;
}

.dateCell {
  min-width: 170px;
}

.greenText {
  color: $success;
}

.redText {
  color: $red;
}

.blueText {
  color: $blue;
}
