@use '~styles/variables' as *;

.button {
  text-align: right;
  margin-bottom: 20px;
}

.downloadButton {
  color: $color-brand;
  text-decoration: none;
  background-color: transparent;
  appearance: none;
  border: none;
  text-align: left;
  padding: 0;
}
