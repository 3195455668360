@import '../../styles/variables/colors';

.container {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
}

.editor {
  background-color: #fff;
  border-radius: 0.25rem 0.25rem 0 0;
  font-family: 'Nunito', sans-serif;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border: none;
  padding: 10px;
}

.contentEditor {
  padding: 8px 20px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border: none;
}

.errorEditor {
  border: 1px solid $error;
}

.errorText {
  color: $error;
}

.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $error;
}

.embeddedPopup,
.imagePopup {
  position: absolute;
  top: 35px;
  left: -200px;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 235px;
  border: 1px solid #f1f1f1;
  padding: 20px 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
}

.linkPopup {
  height: fit-content;
  left: -200px;

  input {
    height: 30px;
    padding: 3px;
    font-size: 15px;
  }

  label {
    display: flex;
    align-items: center;
  }
}

.embeddedPopup {
  height: 210px;
}

.fontSizeBtn {
  scrollbar-width: thin;
  min-width: 55px;
}

@media screen and (max-width: 1185px) {
  .imagePopup {
    left: 16px;
  }
}

@media screen and (max-width: 1150px) {
  .embeddedPopup {
    left: 16px;
  }
}

@media screen and (max-width: 992px) {
  .embeddedPopup,
  .imagePopup,
  .linkPopup {
    left: -200px;
  }
}

@media screen and (max-width: 961px) {
  .imagePopup {
    left: 16px;
  }
}

@media screen and (max-width: 927px) {
  .embeddedPopup {
    left: 16px;
  }
}

@media screen and (max-width: 890px) {
  .linkPopup {
    left: 16px;
  }
}

@media screen and (max-width: 660px) {
  .embeddedPopup,
  .imagePopup,
  .linkPopup {
    left: -200px;
  }
}

@media screen and (max-width: 523px) {
  .imagePopup {
    left: 16px;
  }
}

@media screen and (max-width: 516px) {
  .embeddedPopup,
  .linkPopup {
    left: 16px;
  }
}

@media screen and (max-width: 482px) {
  .embeddedPopup,
  .imagePopup {
    left: -200px;
  }

  .linkPopup {
    left: -100px;
  }
}

@media screen and (max-width: 395px) {
  .embeddedPopup,
  .imagePopup {
    left: -100px;
  }

  .linkPopup {
    left: 0;
  }
}

@media screen and (max-width: 361px) {
  .embeddedPopup {
    left: -200px;
  }

  .imagePopup {
    left: 0;
  }

  .linkPopup {
    left: -150px;
  }
}

@media screen and (max-width: 346.5px) {
  .embeddedPopup {
    left: 16px;
  }
}

@media screen and (max-width: 320px) {
  .imagePopup {
    left: -50px;
  }
}

@media screen and (max-width: 310px) {
  .embeddedPopup {
    left: -50px;
  }

  .linkPopup {
    left: 16px;
  }
}

@media screen and (max-width: 309px) {
  .imagePopup {
    left: -100px;
  }
}
