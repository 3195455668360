@import 'styles/variables/colors.scss';

.popup {
  width: 330px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.popupText {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.errorText {
  color: rgba($danger, 0.8);
}
