@import '../../../styles/variables/colors';

.wrapper {
  border: thin solid $gray;
}

.roleWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.trashButton {
  font-size: 24px;
  color: $danger;
  margin-top: 14px;
  margin-right: 20px;
}

.roleField {
  width: 100%;
}
