@import '../../../styles/variables/colors';

.container {
  width: 100%;
  min-height: 100vh;

  & > div {
    height: 100%;
    min-height: 100vh;
  }
}

.content {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: $white;
  align-items: center;
  justify-content: center;
  display: flex;
}

.contentWrapper {
  width: 350px;
  padding: 0 24px;
  text-align: center;

  & > div {
    text-align: left;
  }
}

.background {
  width: 100%;
  height: 100%;
  background: url('images/authentication-bg.jpg') no-repeat;
  background-size: cover;
  background-position: 100%;
}
