@use '~styles/variables' as *;

.container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.containerDisabled {
  input {
    color: #74788d !important;
    background-color: #eff2f7 !important;
    pointer-events: none;
  }
}

.field {
  position: relative;
  width: 100%;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 20px;
}

.fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saveButton {
  margin-top: 3px;
  margin-left: 12px;

  &Container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.customError {
  margin-left: 10px;
}

.suggestion {
  background-color: white;
  border: 1px solid #ced4da;
  border-top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  position: absolute;
  border-radius: 0.25rem;
  z-index: 100;
}

.suggestionItem {
  transition: opacity 0.2s;
}

.suggestionItem:hover {
  opacity: 0.8;
  cursor: pointer;
}

.errorText {
  color: $color-danger;
}

.toggleButton {
  position: absolute;
  top: 40px;
  left: -15px;
  border: 7px solid transparent !important;
  border-left: 10px solid $color-brand !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important;
  transform-origin: 5px center !important;
  transform: rotate(0deg);
  transition: transform 0.3s linear !important;

  &Opened {
    transform: rotate(90deg);
  }
}

.toggleButton,
.toggleButton:active,
.toggleButton:focus {
  outline: none !important;
  box-shadow: none !important;
}
