@import '../../styles/variables/colors';

.table {
  margin-bottom: 60px !important;
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    transition: 0.2s;

    &:not(:first-child) {
      border-left: none !important;
    }
  }
}

.column {
  width: 25%;
}

.columnMedium {
  width: 20%;
}

.columnSmall {
  width: 5%;
}

.columnTitle {
  width: 20%;
}

.error {
  color: $danger;
  margin-top: -40px;
}
