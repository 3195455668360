.cameraFormModal {
  width: 582px !important;
  max-width: none !important;
  border-radius: 15px !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);

  & > .modal-content {
    border-radius: 15px !important;
  }
}

.modalAddNew {
  position: absolute;
  right: 0;
}

.title {
  font-size: 22px;
  text-align: center;
  user-select: none;
}

.textInput {
  background: #f4f4f4;
  padding: 21px 14px !important;
  border: none;
  box-sizing: border-box;
  margin-top: 13px;
  font-size: 18px;

  &::placeholder {
    color: #343a40 !important;
    opacity: 0.5 !important;
    font-size: 18px;
  }
}

.inputError {
  border: 2px solid red;
}

.direction {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;

  select {
    position: absolute;
    width: 84px;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  span {
    margin-left: 2px;
    text-decoration: underline;
  }
}

.cameraFormDelete {
  background: none;
  border: none;
  color: #74788d;

  i {
    margin-right: 5px;
    top: 2px;
    position: relative;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
}

.disabledButton {
  pointer-events: none;
  opacity: 0.5;
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.cameraFormHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;

  h3 {
    margin: 0;
    text-align: center;
  }

  & > * {
    flex-basis: 33%;
  }
}

.cameraFormFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}

.customSelect {
  background: #f4f4f4;
  margin-top: 13px;
  font-size: 18px !important;

  & > div:nth-child(2) {
    background: none;
    border: none;
    padding: 21px 4px !important;
  }

  & > div:nth-child(3) {
    background: #f4f4f4;
    border: none;
  }
}
