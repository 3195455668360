.head {
  &WithSubhead {
    padding-top: 1rem !important;
    text-align: center;
  }
}

.subhead {
  display: flex;
  justify-content: space-between;

  &Col {
    padding: 5px 10px;
    text-align: center;
  }
}

.subContentContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  padding: 5px;
}
