.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.disabled {
  opacity: 0.2;
  transition: 0.2s;
  cursor: not-allowed;
}

.checkbox {
  display: flex !important;
  align-items: center;
  padding-left: 0 !important;

  label {
    margin-bottom: 0;
  }

  div {
    margin-left: 10px;
  }
}

.lastEmail {
  line-height: 1.1;
}

.sendPassword {
  margin-left: 10px;
}

.actionColumn {
  width: 8em;
}
