.preloaderContainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #f1f5f7;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImage {
  margin-left: -0.5rem;
}
