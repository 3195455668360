.infoText {
  font-size: 1rem;
  font-weight: 400;
  margin: 0.2rem 0 0.5rem 0;
}

.subHeading {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0.2rem 0 0.5rem 0;
}

.selectedInformation {
  height: 30px;
  justify-content: flex-end;
  margin-top: auto;
  margin-left: 1rem;
}

.actionColumn {
  height: 30px;
  justify-content: flex-end;
  margin-top: auto;
  margin-right: 1.5rem;
}
