.root {
  display: inline-flex;
  flex-direction: column;
}

.opacity {
  opacity: 0.5;
  transition: opacity 0.2s;
}

.arrow {
  height: 1px;
}
