.infoSection {
  .key {
    font-weight: 600;
    flex-shrink: 0;
  }

  .value {
    text-align: right;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    flex: 1;
  }
}
