.calendar {
  width: fit-content;
  background: #fff;
  border-radius: 16px;
  padding: 18px 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 8px 0 rgb(34 60 80 / 20%);
}

.daterange {
  height: 38px;
}

.roundedButton {
  width: 56px;
  height: 56px;
  border: none !important;
  box-sizing: border-box !important;
  padding: 0 !important;
  margin: 0 !important;
  background: #fff !important;
  border-radius: 28px !important;
}

.icon {
  color: #505d69 !important;
  font-size: 19px;
  opacity: 0.85;
}

.toggleLiteral {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  margin-top: 50px !important;

  h2 {
    user-select: none;
    position: relative;
    margin: 0 !important;
    margin-top: -2px !important;
    margin-right: 20px !important;
  }
}
