.popupContainer {
  max-width: 780px;
  max-height: 600px;
}

.container {
  position: relative;
  width: 780px;
  overflow-y: scroll;
  max-height: 540px;
  min-height: 300px;
  max-width: calc(100% - 40px);
  margin: 20px auto 0;
  border-radius: 12px;

  button,
  a {
    pointer-events: none;
  }
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -3rem;
  margin-left: -3rem;
  width: 6rem !important;
  height: 6rem !important;
  border-width: 0.3rem !important;
}
