@import 'styles/variables/colors';

p {
  margin-bottom: 0 !important;
}

.actionWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.withoutPadding {
  vertical-align: middle !important;
}

.table {
  border-top: none;
  margin-bottom: 100px !important;
}

.head {
  border-top: none !important;

  &WithSubhead {
    padding: 0 !important;
    text-align: center;
  }
}

.titleCell {
  width: 50%;
}

.input {
  width: 71px;
  height: 34px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:disabled {
    background-color: rgba(black, 0.03);
  }
}

.sum {
  width: 71px;
  height: 34px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  background-color: rgba(black, 0.03);
}

.cellContainer {
  justify-content: center;
}

.cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(2n + 1) {
    width: 50px;
  }

  &:nth-child(2) {
    width: 71px;
  }

  &Delta {
    margin-bottom: 0;
  }

  &Data {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.subhead {
  display: flex;

  &Col {
    padding: 5px;
    text-align: center;

    &:nth-child(2n + 1) {
      width: 50px;
    }

    &:nth-child(2) {
      width: 71px;
    }

    &:not(:last-child) {
      border-right: 1px solid #eff2f7;
    }
  }
}

.error {
  margin-top: 10px;
  color: red;
}
