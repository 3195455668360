.error {
  :global(.css-yk16xz-control) {
    border-color: red;
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.calendar {
  position: absolute;
  margin-top: 70px;
  margin-left: -4px;
  z-index: 999;
}

.calendarContainer {
  margin: 10px 0;
}
