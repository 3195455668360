.textarea {
  resize: none;
  min-height: 150px;
}

.colButton {
  text-align: right;
  margin-bottom: 20px;
}

.refundBlock {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1190px) {
    flex-wrap: wrap;
  }

  @media (max-width: 990px) {
    flex-wrap: nowrap;
  }
}

.refundContent {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: 1190px) {
    flex-wrap: wrap;
  }

  @media (max-width: 990px) {
    flex-wrap: nowrap;
  }
}

.selectBlock {
  display: flex;
  align-items: center;
  width: 100%;
}

.selectTotal {
  font-size: 16px;
  margin-right: 10px;
  font-weight: 500;
}

.refundDescription {
  font-size: 16px;
  margin-right: 10px;
  font-weight: 500;
  margin-top: 10px;
}

.select {
  width: 100%;
  min-width: 100px;
  margin-right: 10px;

  @media (max-width: 1190px) {
    margin-right: 0;
  }

  @media (max-width: 990px) {
    margin-right: 10px;
  }
}

.button {
  @media (max-width: 1190px) {
    margin-top: 5px;
    width: 100%;
  }

  @media (max-width: 990px) {
    margin-top: 0;
    width: auto;
  }
}

.loading {
  margin-left: 5px;
}
