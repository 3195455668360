@import 'styles/variables/colors';

.blueText {
  color: $blue;
}

.greenText {
  color: $success;
}

.redText {
  color: $red;
}
