.big {
  width: 200px;
}

.small {
  width: 100px;
}

.normal {
  width: 150px;
}

.w300 {
  width: 300px;
}
