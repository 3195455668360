@import 'styles/variables/colors.scss';

.title {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: $secondary;
  font-family: 'Nunito', sans-serif;
}

.cell {
  min-width: 130px;
}

.cellBig {
  min-width: 150px;
}
