.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.table {
  margin-bottom: 0;
}

.card {
  max-height: calc(100vh - 225px);
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.card::-webkit-scrollbar {
  display: none;
}

.checkbox {
  padding-left: 30px;
}

.cellCheckbox {
  width: 140px;
}
