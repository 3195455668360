@import '../../../styles/variables/colors.scss';

.title {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: $secondary;
  font-family: 'Nunito', sans-serif;
}

.cell {
  min-width: 100px;
}

.cellBig {
  min-width: 150px;
}

.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}
