@use '~styles/variables' as *;

.title {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: $color-secondary;
}

.number {
  text-align: center;
  margin-top: 8px;
  font-weight: bold;
  font-size: 32px;
  color: $color-dark;
  font-family: 'Inter', sans-serif;
}

.sourceList {
  margin-top: 16px;
}

.source {
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  list-style: none;
  border-top: thin solid rgba($color-gray, 0.25);

  &Title {
    font-weight: 500;
    font-size: 16px;
  }

  &Number {
    font-size: 16px;
    font-weight: 500;
    color: $color-secondary;
    font-family: 'Inter', sans-serif;
  }
}
