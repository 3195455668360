@import 'styles/variables/colors.scss';

.container {
  position: relative;
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}
