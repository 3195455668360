@import '../../styles/variables/colors';

.container {
  outline: none;
  background: rgba($white, 0.7);
  color: rgba($black, 0.2);
  border: none;
  padding: 6px 18px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 15px;
  margin-bottom: 10px;
  transition: 0.2s;
  font-size: 12px;
  line-height: 16px;
}

.activeContainer {
  background-color: $white;
  color: $gray-dark;
}
