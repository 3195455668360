@use '~styles/variables' as *;

.table {
  :global(.table-responsive) {
    overflow: visible;
  }
}

.cell {
  min-width: 150px;
}

.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}

.trash {
  color: $color-red;
}
