@import 'styles/variables/colors';

.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.fileInput {
  box-sizing: border-box;
  width: auto;
  display: inline-block;
  margin-bottom: 0 !important;
  margin-left: 10px;
  top: 6px;
  position: relative;
  height: 38px;

  button {
    height: 38px;
    border-radius: 0.25rem;
    background: $brand;

    &:hover {
      background-color: $orange;
      border-color: $orange;
    }
  }

  label {
    display: none;
  }
}
