@import 'styles/variables/colors';

.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100001;
  background-color: rgba(3, 6, 16, 0.4);
  animation: fadeIn 0.3s;
}

.popupBase {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  height: 100%;
  max-width: 540px;
  padding: 1rem;
  border-radius: 10px;
  transition: 0.2s;
  animation: zoomIn 0.3s;
  box-shadow: 0 8px 26px 0 rgba($dark, 0.61);
  background: $white;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5, 0.5);
  }

  100% {
    transform: scale(1, 1);
  }
}
