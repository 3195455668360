.cameraStatsCardLive {
  border-radius: 20px !important;
  background: #0d0d0d !important;
  box-shadow: none !important;
  height: 203px;

  h1 {
    color: white;
    font-size: 21px;
    margin-bottom: 0 !important;
  }

  & > div {
    padding: 33px 19px !important;
  }
}

.cameraStatsCardDefault {
  border-radius: 5px !important;
  box-shadow: none !important;
  min-height: 203px;

  & > div {
    padding: 27px 21px !important;
  }

  h1 {
    color: #505d69;
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 0 !important;
  }
}

.currentValue {
  font-weight: bold !important;
  font-size: 30px;
  color: #343a40;
  margin-top: 0 !important;
  font-family: 'Inter', sans-serif;
  margin-bottom: 0 !important;
  animation: fadein 0.5s ease-in-out 1s both;
  display: block;

  @media screen and (min-width: 1600px) {
    font-size: 40px;
  }
}

.description {
  font-size: 18px;
  color: #74788d;
  animation: fadein 0.5s ease-in-out 1s both;
}

.graphContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: 23px;
  bottom: 25px;
  animation: fadein 0.5s ease-in-out 1s both;

  .graphLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .liveComparison {
    font-weight: bold;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: row;

    .icon {
      width: 20px;
      height: 20px;
      background: red;
      border-radius: 20px;
      display: block;
      margin-right: 5px;
      top: 4px;
      position: relative;
    }

    &.liveComparisonMore {
      color: #2f9908;

      .icon {
        background: rgba(47, 153, 8, 0.2);
        position: relative;

        &::after {
          content: '';
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #2f9908;
          top: 7px;
          position: absolute;
          left: 5px;
        }
      }
    }

    &.liveComparisonLess {
      color: #515fcb;

      .icon {
        background: rgba(81, 95, 203, 0.1);
        position: relative;

        &::after {
          content: '';
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #515fcb;
          top: 10px;
          position: absolute;
          left: 5px;
        }
      }
    }
  }

  .graph {
    display: block;
    width: 105px;
    height: 40px;
  }

  .description {
    font-size: 16px;
    font-weight: bold !important;
    color: #b2b3bd;
  }
}

.line {
  background: #eff2f7;
  height: 1px;
  display: block;
  margin-top: 15px !important;
  margin-left: -21px;
  margin-right: -21px;
}

.comparisonLine {
  display: flex;
  flex-direction: row;
  margin-top: 22px;
  align-items: center;
}

.comparisonValue {
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  border-radius: 5px;
  background: #eee;
  padding: 3px 8px;
  margin-right: 10px;
  // transition: all 0.3s linear;
  height: 26px;
  min-width: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: fadein 0.5s ease-in-out 0.2s both;

  .arrow {
    margin-right: 5px;
  }
}

.comparisonValueMore {
  background: rgba(47, 153, 8, 0.180392);
  color: #2f9908;

  .arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f9908;
  }
}

.comparisonValueLess {
  background: rgba(81, 95, 203, 0.180392);
  color: #515fcb !important;

  .arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #515fcb;
  }
}

.lastReportAt {
  background: rgba(252, 252, 252, 0.1);
  height: 32px;
  position: absolute;
  right: 20px;
  top: 27px;
  border-radius: 10px;
  font-size: 17px;
  color: #fcfcfc;
  font-weight: bold !important;
  box-sizing: border-box;
  padding: 4px 10px;
  text-align: center;
  padding-right: 35px;
  min-width: 40px;

  i {
    color: #84888b;
    position: absolute;
    right: 10px;
  }
}

.valueLine {
  margin-top: 42px;

  .currentValue {
    font-size: 70px;
    color: #fff;
    line-height: 1;
    margin-bottom: auto !important;
    transform: translateY(10px);
    position: relative;
    display: block;
  }
}

.comparisonValuesEqual {
  .arrow {
    display: none;
  }
}

$loaderSize: 18px;

.loader {
  height: $loaderSize;
  width: $loaderSize;
  -webkit-animation: loader-1 4.8s linear infinite;
  animation: loader-1 4.8s linear infinite;
  position: absolute;
  top: 27px;
  right: 21px;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translate3d(0, 5px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes loader-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader-1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: $loaderSize;
  width: $loaderSize;
  clip: rect(0, $loaderSize, $loaderSize, ($loaderSize / 2));
  -webkit-animation: loader-2 1.2s linear infinite;
  animation: loader-2 1.2s linear infinite;
}

@-webkit-keyframes loader-2 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(220deg);
  }
}

@keyframes loader-2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(220deg);
  }
}

.loader span::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: $loaderSize;
  width: $loaderSize;
  clip: rect(0, $loaderSize, $loaderSize, ($loaderSize / 2));
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: loader-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

.loaderDark span::after {
  border: 3px solid #505d69;
}

@-webkit-keyframes loader-3 {
  0% {
    -webkit-transform: rotate(-140deg);
  }

  50% {
    -webkit-transform: rotate(-160deg);
  }

  100% {
    -webkit-transform: rotate(140deg);
  }
}

@keyframes loader-3 {
  0% {
    transform: rotate(-140deg);
  }

  50% {
    transform: rotate(-160deg);
  }

  100% {
    transform: rotate(140deg);
  }
}
