@use '~styles/variables' as *;

.table {
  :global(.table-responsive) {
    overflow: visible;
  }
}

.button {
  margin-top: 5px;
}
