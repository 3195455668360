.cardHeader {
  display: flex;
  justify-content: space-between;
}

.customer {
  font-size: 19px;
  font-weight: 700;
}

.customerId {
  font-size: 13px;
}

.cardBody {
  margin-top: 30px;
}

.cardItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  i {
    font-size: 20px;
    margin-right: 5px;
  }

  p {
    font-size: 15px;
    word-break: break-all;
  }
}
