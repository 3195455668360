.preloader {
  position: relative !important;
  height: 300px !important;
  top: 0 !important;

  &White {
    background-color: white !important;
  }
}

.totalPrice {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 10px;

  h6 {
    margin-right: 5px;
  }
}
