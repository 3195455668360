@import '../../styles/variables/colors';

.heading {
  text-align: center;
  margin: 0 0 0.5rem 0;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
}

.headerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.toggleButton {
  background: none;
  border: none;
  cursor: pointer;
  float: right;
  padding: 0;
  margin-left: 1rem;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  img {
    display: block;
    border-style: none;
  }
}

.boldText {
  font-weight: bold;
}

.danger {
  color: $red;
}

.spacing {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.lastDateEntryContainer {
  border-bottom: none;
}

.entryContainer {
  margin-top: 1rem;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.flexCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexEnd {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.colStyle {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.disabledDropdown {
  background-color: #f0f0f0 !important;
  cursor: not-allowed;
}

.badgeMainContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
}

.holidayBadge {
  color: $black;
  background-color: $light-yellow;
  padding: 0.25em 0.4em;
  font-size: 75%;
  border-radius: 0.25rem;
}

.summary {
  background-color: #f1f5f7;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 5px;
}

.approveButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.calcHoursForDate {
  color: $black;
  padding: 0.25em 0.4em;
  border-radius: 0.25rem;
}

.totalForDate {
  background-color: #f1f5f7;
}

.overtimeForDate {
  background-color: $pastel-red;
}

.approvedOvertime {
  margin-top: 1rem;
}

.submittedTotal {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0;
}

.approvedTotal {
  background: $success;
  color: $white;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.brandTitle {
  font-weight: 600;
}
