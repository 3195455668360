@use '~styles/variables' as *;

.disabled {
  color: rgba($color-brand, 0.5) !important;
  border-color: rgba($color-brand, 0.5) !important;
}

.button {
  width: 120px;

  &Block {
    height: 100%;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.customer {
  border-bottom: 1px solid rgba($color-secondary, 0.5);
  margin-bottom: 10px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;

  &Active {
    max-height: 1000px;
    transition: max-height padding-bottom 0.4s ease-in;
    padding-bottom: 10px;
  }
}

.empty {
  font-size: 24px;
  text-align: center;
}

.total {
  display: flex;
  justify-content: space-between;

  &Text {
    font-size: 20px;
    font-weight: bold;
  }
}

.exciseField {
  z-index: 999;
  margin-bottom: 1rem;
}
