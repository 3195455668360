@import 'styles/variables/colors.scss';

.disabled {
  color: rgba($brand, 0.5) !important;
  border-color: rgba($brand, 0.5) !important;
}

.button {
  width: 120px;

  &Block {
    height: 100%;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.customer {
  border-bottom: 1px solid rgba($secondary, 0.5);
  margin-bottom: 10px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;

  &Active {
    max-height: 1000px;
    overflow: visible;
    transition: max-height padding-bottom 0.4s ease-in;
    padding-bottom: 10px;
  }
}

.empty {
  font-size: 24px;
  text-align: center;
}

.total {
  display: flex;
  justify-content: space-between;

  &Text {
    font-size: 20px;
    font-weight: bold;
  }
}

.obligationLink {
  padding: 0 !important;
  color: $grey-700;
}

.preloader {
  position: 'absolute';
  width: 100% !important;
  height: 320px !important;
}

.centeredParagraph {
  display: flex;
  align-items: center;
  height: 2.4rem;
}
