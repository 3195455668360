.checkbox {
  display: flex !important;
  align-items: center;
  padding-left: 0 !important;

  label {
    margin-bottom: 0;
  }

  div {
    margin-left: 10px;
  }
}

.wAutoMin {
  min-width: 28.5rem; /* Set your desired minimum width here, e.g., 200px */
}
