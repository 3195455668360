.navigationTabs {
  width: 100%;
  position: relative;
}

.addCamera {
  background: none;
  border: none;
  color: #808191;
  position: absolute;
  right: 10px;
  bottom: 14px;

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  i {
    top: 2px;
    position: relative;
    margin-right: 5px;
  }
}

.tab {
  color: #808191 !important;
  transition: color 0.3s linear;
  display: flex !important;
  align-items: center;
  flex-direction: row;
  padding: 8px 10px !important;
  padding-right: 14px !important;

  &::after {
    background: #343a40 !important;
  }
}

.tabBullet {
  width: 10px;
  height: 10px;
  position: relative;
  background: #e4e4e4;
  border-radius: 5px;
  margin-right: 10px;
  user-select: none;
  transition: color 0.3s linear;

  &--running {
    background: #2f9908;
  }

  &--stopped {
    background: #808191;
  }
}

.navLink {
  padding: 15px 20px !important;
}

.toggleCamera {
  position: absolute;
  right: 3px !important;
  width: 14px !important;
  height: 14px !important;
  border: none !important;
  background: none !important;
  padding: 0 !important;
  color: #343a40 !important;

  i {
    position: absolute;
    top: 0 !important;
    margin-top: -3px !important;
    right: 0 !important;
    color: #808191 !important;
  }

  &--offline {
    cursor: default !important;

    i {
      color: #2a2a2d !important;
    }
  }
}
