@use '~styles/variables' as *;

.subHeading {
  font-size: 1.3rem;
  font-weight: 600;
}

.table {
  :global(.table-responsive) {
    overflow: visible;
  }
}

.cell {
  min-width: 150px;
}

.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}

.trash {
  color: $color-red;
}

.edit {
  color: $color-blue;
}

.checkbox {
  display: flex !important;
  align-items: center;
  padding-left: 0 !important;

  label {
    margin-bottom: 0;
  }

  div {
    margin-left: 10px;
  }
}

.root {
  transition: opacity 0.25s;
}

.loading {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.danger {
  background-color: $color-light-danger;
}

.warning {
  background-color: $color-light-warning;
}

.spacing {
  margin-bottom: 20px;
}
