.button {
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  padding: 0;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 600;
}

.arrow {
  font-size: 18px;
}
