.requestPaymentWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.brandContainer {
  width: 100%;
}

.pMargin {
  margin: 0.8rem;
  text-align: center;
}
