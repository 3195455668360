@import '../../styles/variables/colors';

.actionWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.withoutPadding {
  padding: 0 !important;
  vertical-align: middle !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.extraMargin {
  input {
    margin-left: 4px;
  }
}

.table {
  border-top: none;
}

.head {
  border-top: none !important;

  &WithSubhead {
    padding: 0 !important;
    text-align: center;
  }
}

.titleCell40 {
  width: 40%;
}

.titleCell60 {
  width: 60%;
}

.skuCell20 {
  width: 20%;
}

.skuCell30 {
  width: 30%;
}

.input {
  width: 71px;
  height: 34px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:disabled {
    background-color: rgba(black, 0.03);
  }
}

.sum {
  width: 71px;
  height: 34px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  background-color: rgba(black, 0.02);
}

.cellContainer {
  justify-content: center;
}

.emptyCell {
  background-color: rgba(black, 0.02);
}

.inputCell {
  padding: 0.5em 0;
}

.inputCellBorder {
  border-bottom: 1px solid #eff2f7;
  border-right: 1px solid #eff2f7;
}

.cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(2n + 1) {
    width: 50px;
  }

  &:nth-child(2) {
    width: 71px;
  }

  &Delta {
    margin-bottom: 0;
  }

  &Data {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.subhead {
  display: flex;

  &Col {
    padding: 5px;
    text-align: center;

    &:nth-child(2n + 1) {
      width: 50px;
    }

    &:nth-child(2) {
      width: 71px;
    }

    &:not(:last-child) {
      border-right: 1px solid #eff2f7;
    }
  }
}

.buttonsContainer {
  display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  gap: 20px;
}

.select {
  margin-bottom: 10px;
}

.danger {
  background-color: $light-danger;
}
