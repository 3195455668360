@import 'styles/variables/colors';

p {
  margin-bottom: 0 !important;
}

.actionWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.withoutPadding {
  vertical-align: middle !important;
}

.table {
  border-top: none;
  margin-bottom: 100px !important;
}

.cell {
  vertical-align: middle !important;
}

.column {
  &Large {
    width: 50%;
    min-width: 250px;
  }

  &Main {
    width: 20%;
    min-width: 150px;
  }

  &Small {
    width: 10%;
    min-width: 70px;
  }
}

.error {
  margin-top: 10px;
  color: red;
}
