.calendar {
  width: fit-content;
  background: #fff;
  border-radius: 16px;
  padding: 18px 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 8px 0 rgb(34 60 80 / 20%);
}

.reportBtn {
  display: flex;
  justify-content: flex-end;
}
