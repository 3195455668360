.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.pendingContainer {
  opacity: 0.2;
  transition: 0.2s;
}
