@import '../../styles/variables/colors';

.periodCardHeader {
  color: #212529 !important;
  font-family: 'Inter', sans-serif !important;
  border-radius: 0 !important;
  padding: 0.25rem 0.75rem !important;

  h6 {
    margin-bottom: 0.2rem !important;
  }
}

.periodCardBody {
  padding: 0.75rem !important;
  padding-bottom: 1.25rem !important;
}

.periodBadge {
  margin-left: 5px !important;
}

.cardFooter {
  padding: 0 !important;
}

.reviewPeriod {
  margin-right: -0.25rem !important;
}

.noTimesheets {
  margin-right: -0.25rem !important;
  pointer-events: none !important;
  color: #6c757d !important;
}
