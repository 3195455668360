@import '../../styles/variables/colors';

.colorWrapper {
  position: relative;
}

.colorCardWrapper {
  display: flex;
  align-items: center;
}

.color {
  margin-bottom: 0;
  margin-left: 0.6rem;
  font-weight: 600;
  text-transform: uppercase;
}

.colorCard {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  margin-top: 4px;
  border: none;
  outline: none;
  display: block;
}

.colorPicker {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
