.selectTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.countTitle {
  font-weight: 700;
}

.count {
  font-size: 28px;
  font-weight: 700;
}
