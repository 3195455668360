@import '../../styles/variables/colors';

.colorWrapper {
  display: flex;
  align-items: center;
}

.colorCard {
  width: 20px;
  border-radius: 4px;
  height: 20px;
  margin-right: 14px;
}

.colorText {
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
}

.wineclubSelect {
  margin-bottom: -8px;
}

.brandColumn {
  width: 23%;
}
