.sidebarAlert {
  background-color: #cb444a;
  border-radius: 10px;
  color: #fff !important;
  padding: 1em;
  margin: 1em;
}

.title {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px !important;
}

.subtitle {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin-top: 5px;
}

.date {
  color: #fff;
  max-width: calc(100% - 15px);
  font-size: 14px;
  line-height: 150%;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}
