.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
}

.button {
  position: absolute;
  left: 0;
  opacity: 0.5;
}

.cellCheckbox {
  width: 140px;
}

.disabledContainer {
  opacity: 0.4;
  user-select: none;
}

.tag {
  font-size: 10px;
  font-weight: 700;
  background: #f1f5f7;
  padding: 5px;
  line-height: 100%;
  width: fit-content;
}
