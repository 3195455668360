@import '../../../styles/variables/colors';

.preloader {
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.completed {
  color: $green;
}

.failed,
.errored,
.error {
  color: $red;
}
